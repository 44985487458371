/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'influencers',
        title: 'Influenserzy',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/influencers',
    },
    {
        id: 'companies',
        title: 'Firmy',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/companies',
    },
    {
        id: 'activeCooperation',
        title: 'Aktywne współprace',
        type: 'basic',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/activeCooperation',
    },
    {
        id: 'collaborations',
        title: 'Współprace do podjęcia',
        type: 'basic',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/collaborations',
    },
    {
        id: 'yourCollaborations',
        title: 'Twoje współprace',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/yourCollaborations',
    },
    {
        id: 'profile',
        title: 'Twoje dane',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
    },
    {
        id: 'posts',
        title: 'Posty',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/posts',
    },
    {
        id: 'newInfluencers',
        title: 'Zgłoszenia Nowych Influeserów',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/newInfluencers',
    },
];

//====================================================

export const unauthorizedUserNavigation: FuseNavigationItem[] = [
    {
        id: 'profile',
        title: 'Twoje dane',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
    },
    // {
    //     id: 'newInfluencers',
    //     title: 'Zgłoszenia Nowych Influeserów',
    //     type: 'basic',
    //     icon: 'heroicons_outline:paper-airplane',
    //     link: '/newInfluencers',
    // },
];

export const influenserNavigation: FuseNavigationItem[] = [
    {
        id: 'collaborations',
        title: 'Współprace do podjęcia',
        type: 'basic',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/collaborations',
    },
    {
        id: 'yourCollaborations',
        title: 'Twoje współprace',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/yourCollaborations',
    },
    {
        id: 'profile',
        title: 'Twoje dane',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
    },
];

export const adminNavigation: FuseNavigationItem[] = [
    {
        id: 'influencers',
        title: 'Influenserzy',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/influencers',
    },
    {
        id: 'companies',
        title: 'Firmy',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/companies',
    },
    {
        id: 'activeCooperation',
        title: 'Aktywne współprace',
        type: 'basic',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/activeCooperation',
    },
    {
        id: 'collaborations',
        title: 'Współprace do podjęcia',
        type: 'basic',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/collaborations',
    },
    {
        id: 'posts',
        title: 'Posty',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/posts',
    },
    {
        id: 'newInfluencers',
        title: 'Zgłoszenia Nowych Influeserów',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/newInfluencers',
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'A',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'B',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'C',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
